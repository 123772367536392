import { Injectable } from '@angular/core';

import { SessionAttachmentCreateDto, SessionAttachmentDto } from '../dtos/session/session-attachment.dto';
import { SessionAttachment } from '../models/session-attachment';

import { MapperFromDto, MapperToDto } from './mappers';

/** Sesstion attachment mapper. */
@Injectable({ providedIn: 'root' })
export class SessionAttachmentMapper implements
MapperFromDto<SessionAttachmentDto, SessionAttachment>,
MapperToDto<SessionAttachmentCreateDto, SessionAttachment> {

	/** @inheritdoc */
	public fromDto(dto: SessionAttachmentDto): SessionAttachment {
		return {
			id: dto.id,
			name: dto.name,
			url: dto.pre_signed_url,
			sessionId: dto.consultation_id,
		};
	}

	/** @inheritdoc */
	public toDto(attachment: SessionAttachment): SessionAttachmentCreateDto {
		return {
			name: attachment.name,
			file: attachment.url,
		};
	}
}
