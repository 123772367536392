import { z } from 'zod';

const sessionAttachmentSchemaBase = z.object({
	id: z.number(),
	name: z.string(),
	file: z.string(),
	consultation_id: z.number(),
	pre_signed_url: z.string(),
});

/** Validation schema for session attachment dto. */
export const sessionAttachmentSchema = sessionAttachmentSchemaBase.pick({
	id: true,
	name: true,
	consultation_id: true,
	pre_signed_url: true,
});

/** Session attachment dto. */
export type SessionAttachmentDto = z.infer<typeof sessionAttachmentSchema>;

/** Session attachment create dto. */
export type SessionAttachmentCreateDto = Pick<z.infer<typeof sessionAttachmentSchemaBase>, 'name' | 'file'>;
