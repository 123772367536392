import { z } from 'zod';

import { sessionAttachmentSchema } from './session-attachment.dto';
import { sessionBaseDtoSchema } from './session-base.dto';

/** Validation schema for "Consultation" DTO. */
export const sessionDtoSchema = sessionBaseDtoSchema.extend({
	/** Attachments. */
	attachments: z.array(sessionAttachmentSchema),

	/** Cost without fees. */
	cost: z.number(),

	/** Platform fee. */
	platform_fee: z.number(),

	/** Stripe fee. */
	stripe_fee: z.number(),

	/** Total cost with fees. */
	total_cost: z.number(),
}).readonly();

/** Session DTO. */
export type SessionDto = z.infer<typeof sessionDtoSchema>;
