import { Injectable, inject } from '@angular/core';

import { ConsultationRequest } from '../models/consultation-request';
import { SessionCreateDto } from '../dtos/session/session-create.dto';

import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { EntityValidationErrors } from '../models/app-error';

import { Session, SessionUpdate } from '../models/session';

import { SessionUpdateDto } from '../dtos/session/session-update.dto';
import { SessionDto } from '../dtos/session/session.dto';

import { MapperFromDto, ValidationErrorMapper } from './mappers';
import { extractErrorMessageByField } from './extract-error-message';
import { SessionAttachmentMapper } from './session-attachment.mapper';
import { PaymentCostMapper } from './payment/payment-cost.mapper';
import { SessionBaseMapper } from './session-base.mapper';

/** Mapper for session's entities. */
@Injectable({ providedIn: 'root' })
export class SessionMapper implements
	MapperFromDto<SessionDto, Session>,
	ValidationErrorMapper<SessionCreateDto, ConsultationRequest> {

	private readonly sessionAttachmentMapper = inject(SessionAttachmentMapper);

	private readonly paymentCostMapper = inject(PaymentCostMapper);

	private readonly sessionBaseMapper = inject(SessionBaseMapper);

	/** @inheritdoc */
	public fromDto(data: SessionDto): Session {
		return {
			...this.sessionBaseMapper.fromDto(data),
			attachments: data.attachments.map(a => this.sessionAttachmentMapper.fromDto(a)),
			paymentCost: this.paymentCostMapper.fromDto(data),
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<SessionCreateDto>): EntityValidationErrors<ConsultationRequest> {
		return {
			description: extractErrorMessageByField(errorDto, 'description'),
			cost: extractErrorMessageByField(errorDto, 'total_cost'),
		};
	}

	/**
	 * Map model to dto for consultation request.
	 * @param data Consultation information.
	 */
	public toCreateDto(data: ConsultationRequest): SessionCreateDto {
		return {
			to_user: data.userId,
			session_type: data.sessionType,
			description: data.description,
			attachments: data.files.map(f => ({ file: f.url, name: f.name })),
			note: '',
			total_cost: data.cost,
			template: data.templateId,
		};
	}

	/**
	 * Map session to dto for update.
	 * @param session Session.
	 */
	public toUpdateDto(session: SessionUpdate): SessionUpdateDto {
		return {
			total_cost: session.paymentCost.totalCost,
			description: session.description,
			duration: session.durationInMin,
			note: session.note,
			status: session.status ?? undefined,
			attachments: session?.attachments?.map(attachment => this.sessionAttachmentMapper.toDto(attachment)) ?? null,
		};
	}
}
